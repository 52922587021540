<template>
  <div class="comp-footer">
    <div class="footer-left">
      <div class="top">
        <img style="width:3.17rem;margin-bottom:0.4rem" src="@/assets/images/all/pc-logo2.png" alt="">
        <div class="line"></div>
        <div class="text">
          Chengdu Orecto Import and Export Trading Co.,Ltd is an enterprise specialized in excellent quality of PVC flooring.
        </div>
      </div>
      <div class="bottom flex-s">
        <div v-for="item,index in tabList" :key="index" class="tag flex-c">
          {{item.title}}
        </div>
      </div>
    </div>
    <div class="footer-center">
      <div class="row1">
        Chengdu Orecto Import and Export Trading Co., Ltd
      </div>
      <div v-for="item,index in infoList" :key="index" class="row2">
        <div class="left-title">{{item.title}}</div>
        <div class="right-text">{{item.text}}</div>
      </div>
    </div>
    <div class="footer-right">
      <div class="row1">
        Navigation links
      </div>
      <div class="row2">
        <div class="left">
          <div @click="$router.push(item.path)" v-for="item,index in pathLeftList" :key="index" class="text point">{{item.title}}</div>
        </div>
        <div class="right">
          <div @click="$router.push(item.path)" v-for="item,index in pathRightList" :key="index" class="text point">{{item.title}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabList: [
        {
          title: 'SPC flooring'
        },
        {
          title: 'WPC flooring'
        },
        {
          title: 'LVT flooring'
        },
        // {
        //   title: 'Vinyl flooring'
        // },
      ],
      infoList: [
        {
          title: 'Address',
          text: 'No. 666, Guandong 1st Street, Chengdu High-tech Zone, China (Sichuan) Free Trade Pilot Zone'
        },
        {
          title: 'Phone/WhatsApp',
          // text: '+86 13608202562'
          text: ''
        },
        // {
        //   title: 'WhatsApp',
        //   text: 'https://api.whatsapp.com/send/?phone=8613608202562'
        // },
        {
          title: 'E-mail',
          // text: 'orecto@orectofloor.com'
          text: ''
        },
      ],
      pathLeftList: [
        {
          title: 'Home',
          path: '/'
        },
        {
          title: 'Product',
          path: '/Product'
        },
        {
          title: 'Project',
          path: '/Case'
        },
        {
          title: 'News',
          path: '/News'
        },
      ],
      pathRightList: [
        {
          title: 'FAQ',
          path: '/FAQ'
        },
        {
          title: 'About Us',
          path: '/About'
        },
        {
          title: 'Contact Us',
          path: '/ContactUs'
        },
      ],
    };
  }
}
</script>

<style lang="scss">
.comp-footer {
  padding: 1.34rem 1.4rem;
  box-sizing: border-box;
  background: rgba(0, 94, 60, 1);
  color: #fff;
  font-size: 0.18rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  .footer-left {
    width: 5.4rem;
    margin-right: 1rem;
    flex-shrink: 0;
    .top {
      padding-right: 1rem;
      .line {
        border-bottom: 0.01rem solid #fff;
        margin-bottom: 0.42rem;
      }
      .text {
        margin-bottom: 0.42rem;
        font-weight: 300;
      }
    }
    .bottom {
      .tag {
        padding: 0.04rem 0.1rem;
        border: 0.01rem solid #fff;
        font-weight: 300;
        margin-right: 0.2rem;
      }
    }
  }
  .footer-center {
    width: 5.92rem;
    margin-right: 1.21rem;
    padding-top: 0.3rem;
    flex-shrink: 0;
    .row1 {
      margin-bottom: 0.2rem;
      font-size: 0.24rem;
      font-weight: 600;
    }
    .row2 {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 0.2rem;
      .left-title {
        font-size: 0.2rem;
        margin-right: 0.18rem;
        font-weight: 600;
        // width: 0.91rem;
        flex-shrink: 0;
      }
      .right-text {
        font-size: 0.18rem;
      }
    }
  }
  .footer-right {
    padding-top: 0.3rem;
    .row1 {
      margin-bottom: 0.2rem;
      font-size: 0.24rem;
      font-weight: 600;
    }
    .row2 {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      .left {
        width: 1.6rem;
      }
      .right {
      }
      .text {
        margin-bottom: 0.3rem;
        font-size: 0.22rem;
        font-weight: 300;
      }
    }
  }
}
</style>