<template>
  <div class="comp-header flex-b">
    <div @click="$router.push('/')" class="left-logo point">
      <img class="w100" src="@/assets/images/all/pc-logo.png" alt="" srcset="">
    </div>
    <div class="right-mean">
      <div class="home-header2 flex-b">
        <div class="h-header2-left flex-s">
          <template>
            <div @click="tabItemOnClick(item)" @mouseenter="dhFun" v-for="item in navList" :key="item.id" style="" class="h-header2-item point" :class="{ 'h-header2-item-active': (activeItemId+'').slice(0,1) === item.id+'' }">
              <div style="opacity: 0">{{ item.title }}</div>
              <div class="title">
                {{ item.title }}
              </div>
              <div v-if="item.children.length" class="float-div GSAPheight">
                <div v-for="vItem in item.children" :key="vItem.id" class="float-item flex-b" @click.stop="tabItemOnClick(vItem)">
                  <div class="icon-img" v-if="vItem.icon">
                    <img class="w100 h100" :src="onlineUrl + vItem.icon" alt="" />
                  </div>
                  <div>{{ vItem.title }}</div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
export default {
  data() {
    return {
      activeItemId: null
    };
  },
  watch: {
    '$route': {
      handler(newVal) {
        this.navList.forEach(item => {
          if (item.children.length) {
            item.children.forEach(vItem => {
              if (vItem.path.toLowerCase() === newVal.fullPath.toLowerCase()) {
                this.activeItemId = vItem.id;
              }
            });
          } else {
            if (item.path.toLowerCase() === newVal.fullPath.toLowerCase()) {
              this.activeItemId = item.id;
            }
          }
        });
      }
    }
  },
  computed: {
    navList() {
      return [
        {
          id: 1,
          parentId: null,
          title: "Home",
          path: "/",
          children: [],
        },
        {
          id: 2,
          title: "Product",
          path: "/Product",
          children: [],
        },
        {
          id: 3,
          title: "Project",
          path: "/Case",
          children: [],
        },
        {
          id: 4,
          title: "News",
          path: "/News",
          children: [],
        },
        {
          id: 5,
          title: "Services",
          // path: "/Services",
          children: [
            {
              parentId: 5,
              id: 51,
              title: "Installation Tutorial",
              path: "/Service",
              // icon: "/uploads/20231123/d89616b65f7de7f51158651557021e5c.png",
            },
            {
              parentId: 5,
              id: 52,
              title: "FAQ",
              path: "/Faq",
              // icon: "/uploads/20231122/5436b7724f6f265c6de7b4dc81d0692a.png",
            },
          ],
        },
        {
          id: 6,
          title: "About Us",
          path: "/About",
          children: [],
        },
        {
          id: 7,
          title: "Contact Us",
          path: "/ContactUs",
          children: [],
        }
      ];
    },
  },
  methods: {
    tabItemOnClick(item) {
      if (item.path) {
        this.$router.push({
          path: item.path,
          query: {
            ...this.$router.query,
            ...(item.query || {}),
          },
        });
      }
      if (item.parentId) {
        this.activeItemId = item.parentId;
      } else {
        if (!item.children.length) {
          this.activeItemId = item.id;
        }
      }
    },
    dhFun() {
      gsap.fromTo(
        ".GSAPheight",
        {
          height: "0px",
        },
        {
          height: "auto",
          duration: 0.3,
        }
      );
    },
  }
}
</script>

<style lang="scss">
.comp-header {
  width: 100%;
  height: 1rem;
  padding: 0.27rem 0.6rem;
  box-sizing: border-box;
  font-size: 0.2rem;
  color: #000;
  font-weight: 300;
  .left-logo {
    width: 2.38rem;
  }
  .right-mean {
    .home-header2 {
      height: 1rem;
      padding: 0 1.01rem 0 1.01rem;

      .h-header2-left {
        height: 100%;

        .h-header2-item {
          height: 100%;
          display: flex;
          align-items: center;
          // padding: 0 0.14rem;
          // font-weight: 100;
          position: relative;
          justify-content: center;
          box-sizing: border-box;
          padding: 0.25rem 0.25rem 0.18rem 0.25rem;
          position: relative;
          border-bottom: 0.06rem solid transparent;
          .title {
            position: absolute;
          }

          .float-div {
            overflow: hidden;
            position: absolute;
            background: #fff;
            top: 1rem;
            left: 0;
            opacity: 0;
            // transform: translateX(-50%);
            height: 1px;
            // min-width: 1rem;
            text-align: center;
            z-index: 99;
            box-sizing: border-box;

            .float-item {
              padding: 0.14rem 0.14rem;

              box-sizing: border-box;
              min-width: 1.81rem;
              // font-weight: 500;
              // background: rgb(74, 103, 151, 0.75);
              height: 0;
              padding: 0;

              .icon-img {
                width: 0.23rem;
                height: 0.23rem;
                margin-right: 0.18rem;
              }
            }

            .float-item:hover {
              background: rgba(188, 213, 204, 0.5);
              // font-weight: 500;
            }
          }
        }

        .h-header2-item:hover {
          // background: rgba(0, 131, 84, 1);
          border-bottom: 0.06rem solid rgba(0, 131, 84, 1);
          box-sizing: border-box;
          .title {
            // font-weight: bold;
            // color: #fff;
          }

          .float-div {
            opacity: 1;

            .float-item {
              height: auto;
              padding: 0.14rem 0.24rem;
              // padding-left: 0.24rem;
              white-space: nowrap;
            }
          }
        }

        .h-header2-item-active {
          // border-bottom: 0.03rem solid #fff;
          // background: rgba(0, 131, 84, 1);
          border-bottom: 0.06rem solid rgba(0, 131, 84, 1);
          .title {
            // font-weight: 500;
            // color: #fff;
          }
        }
      }
    }
  }
}
</style>